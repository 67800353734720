<template>

  <div>
    <div class="bg-cover bg-top bg-center h-[160px] sm:h-[160px] md:h-[300px] lg:h-[500px]"
         style="background-image: url(https://kdramadiary.com/wp-content/uploads/2021/03/bossam-steam-the-fate-kdramadiary-i-scaled.jpg)"></div>

    <div class="flex flex-wrap mt-[-30px] sm:mt-[-30px] md:mt-[-80px] lg:mt-[-100px] container mx-auto max-w-[60rem]">

      <div class="w-full sm:w-1/1 md:w-1/1 lg:w-1/4 px-2 mb-3 flex justify-center">
        <div class="shadow-lg">
          <img src="https://s4.anilist.co/file/anilistcdn/staff/large/n111635-jL1n6ao0vMVq.png" class="rounded">
        </div>
        <a href="#"
           class="btn bg-[#CD201F] btn-sm absolute mt-2 right-[6%] sm:right-[4%] md:right-[6%] lg:right-[10%] xl:right-[22%]">
          <i class="uil uil-heart pr-1"></i>
          25
        </a>
      </div>

      <div class="w-full sm:w-1/1 md:w-1/1 lg:w-3/4 px-8 mb-3 flex flex-col">
        <div class="main-text w-full">
          <div class="description-wrap">
            <h2 class="my-3 text-3xl font-bold drop-shadow-3xl text-white text-center lg:text-left">
              Kyulkyung
            </h2>
            <h2 class="mb-5 text-xl drop-shadow-3xl text-white text-center lg:text-left">
              Host
            </h2>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Birth:</span>Jun 26, 1991</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Age:</span>30</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Gender:</span>Male</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Years:</span>2011-Present</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Hometown:</span>Kanagawa Prefecture,
              Japan
            </div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Blood Type:</span>B</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Height:</span>173 cm</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Hobbies:</span>karaoke, gaming</div>
            <div class="leading-loose"><span class="font-bold text-white pr-2">Agency:</span>Across Entertainment</div>
            <div class="grid grid-cols-4 gap-4 py-4 auto-cols-max-4 md:auto-cols-mi-2">
              <a href="#" class="btn bg-[#1877f2] hover:border-accent btn-sm">
                <i class="uil uil-facebook-f pr-1"></i>
                <p class="hidden-sm-and-down">Facebook</p>
              </a>
              <a href="#" class="btn bg-[#CD201F] hover:border-accent btn-sm">
                <i class="uil uil-youtube pr-1"></i>
                <p class="hidden-sm-and-down">Youtube</p>
              </a>
              <a href="#" class="btn bg-[#1DA1F2] hover:border-accent btn-sm">
                <i class="uil uil-twitter pr-1"></i>
                <p class="hidden-sm-and-down">Twitter</p>
              </a>
              <a href="#" class="btn bg-[#258d89] hover:border-accent btn-sm">
                <i class="uil uil-globe pr-1"></i>
                <p class="hidden-sm-and-down">Website</p>
              </a>
            </div>
            <div class="mt-3">
              Won Best Rookie at the 9th Seiyuu Awards (2015) for his role as Inaho Kaizuka in Aldnoah.Zero. Won Best
              Lead Actor at the 14th Seiyuu Awards (2020) for his role as Tanjiro Kamado in Demon Slayer: Kimetsu no
              Yaiba.
            </div>
          </div>


        </div>
      </div>

    </div>

    <div class="container mx-auto max-w-[96rem]">
      <div class="series-container p-4">
        <h2 class="text-2xl font-bold text-white">
          Тоглосон цувралууд
        </h2>
        <div class="divider mt-0 after:bg-base-100 before:bg-base-100"></div>
        <ul class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-4">
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <!--    <div class="actor-profile-page container">-->
  <!--        <div class="ap-header">-->
  <!--            <div class="ap-head">-->
  <!--                <img src="https://s4.anilist.co/file/anilistcdn/staff/large/n111635-jL1n6ao0vMVq.png" alt="">-->
  <!--                <div class="mobile-background"></div>-->
  <!--                <div class="ap-names">-->
  <!--                    <div class="ap-name">Kyulkyung</div>-->
  <!--                    <div class="ap-name-alt">Host</div>-->
  <!--                </div>-->
  <!--                <div class="ap-like">-->
  <!--                    <el-tag class="ap-count" effect="dark"><i class="el-icon-collection-tag"></i> 25</el-tag>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--        <div class="ap-body">-->
  <!--            <div class="container ap-body-in">-->
  <!--                <div class="mobile-background"></div>-->
  <!--                <div class="description-wrap">-->
  <!--                    <div class="data-point"><span class="label">Birth:</span>Jun 26, 1991</div>-->
  <!--                    <div class="data-point"><span class="label">Age:</span>30</div>-->
  <!--                    <div class="data-point"><span class="label">Gender:</span>Male</div>-->
  <!--                    <div class="data-point"><span class="label">Years:</span>2011-Present</div>-->
  <!--                    <div class="data-point"><span class="label">Hometown:</span>Kanagawa Prefecture, Japan</div>-->
  <!--                    <div class="data-point"><span class="label">Blood Type:</span>B</div>-->
  <!--                    <div class="data-point"><span class="label">Height:</span>173 cm</div>-->
  <!--                    <div class="data-point"><span class="label">Hobbies:</span>karaoke, gaming</div>-->
  <!--                    <div class="data-point"><span class="label">Agency:</span>Across Entertainment</div>-->
  <!--                    <div class="ap-social">-->
  <!--                        <el-button size="mini" class="ap-facebook">Facebook</el-button>-->
  <!--                        <div class="play-score-vertical"></div>-->
  <!--                        <el-button size="mini" class="ap-youtube">Youtube</el-button>-->
  <!--                        <div class="play-score-vertical"></div>-->
  <!--                        <el-button size="mini" class="ap-twitter">Twitter</el-button>-->
  <!--                        <div class="play-score-vertical"></div>-->
  <!--                        <el-button size="mini" class="ap-website">Website</el-button>-->
  <!--                    </div>-->
  <!--                    <div class="ap-others">-->
  <!--                        Won Best Rookie at the 9th Seiyuu Awards (2015) for his role as Inaho Kaizuka in Aldnoah.Zero. Won Best Lead Actor at the 14th Seiyuu Awards (2020) for his role as Tanjiro Kamado in Demon Slayer: Kimetsu no Yaiba.-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--        <div class="container">-->


  <!--            <h3>2022</h3>-->
  <!--            <div class="card-content">-->
  <!--                <div>-->
  <!--                    <div v-if="isLoading">-->
  <!--                        <my-fund-placeholder></my-fund-placeholder>-->
  <!--                    </div>-->

  <!--                    <div v-else class="collections-wrap is-active">-->
  <!--                        <div class="archive">-->
  <!--                            <article class="article" v-for="item in episodes" :key="item.index">-->
  <!--                                <div class="series-container">-->
  <!--                                    <div>-->
  <!--                                        <div class="related-tiles">-->
  <!--                                            <a :href="`/#/watch/series/episode/`+item.id" class="related-tile">-->
  <!--                                                <div class="related-social">-->
  <!--                                                    <el-button size="mini" class="x-mini" type="primary" icon="el-icon-share"></el-button>-->
  <!--                                                    <el-tag size="mini" effect="dark" type="info" v-show="item.age_limit!= null">{{ item.age_limit }}</el-tag>-->
  <!--                                                </div>-->
  <!--                                                <img-->
  <!--                                                    src="https://animax.mn/assets/max/images/poster.png"-->
  <!--                                                    v-lazy:background-image="$helper.fixImagePath(item.image)"-->
  <!--                                                    class="poster"-->
  <!--                                                />-->
  <!--                                                <div class="related-details"  v-if="$app == 'wemax'">-->
  <!--                                                    <span class="related-title"  v-if="$app == 'wemax'">{{ item.title }}</span>-->
  <!--                                                    <span class="related-info" v-html="item.description"></span>-->
  <!--                                                </div>-->
  <!--                                            </a>-->
  <!--                                        </div>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                            </article>-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->

</template>

<script>

// import Loader from "../components/loader.vue";
// import MyFundPlaceholder from "../components/placeholders/my-fund-placeholder.vue";
import {mapActions} from "vuex";

export default {
  props: ["url", "hlink"],
  components: {
    // Loader,
    // MyFundPlaceholder,
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      page: 1,
      episodes: [],
    };
  },

  computed: {},

  created() {
    // this.getEpisodes(1, false);
  },

  methods: {
    ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),
  }
};
</script>
